import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {IconMenu, IconSwiggly} from './Icons.js';

const Navigation = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <nav className="container-nav">
      <NavLink to='/' tabIndex="0" className="nav-left-col">
        <h1>Patricia • 梧舒灵</h1>
        <IconSwiggly />
      </NavLink>
      <ul className={click ? "nav-list mobile-menu-shown" : "nav-list mobile-menu-hidden"}>
        <li onClick={closeMobileMenu}><NavLink to="/" className="button" activeclassname="">Home</NavLink></li>
        {/* <li onClick={closeMobileMenu}><NavLink to="/about" className="button" activeclassname="nav-active">About</NavLink></li> */}
      </ul>
      <div className="mobile-menu-indicator" onClick={handleClick}>
        {click ? <IconMenu mobileMenuState="mobile-menu-opened" /> : <IconMenu mobileMenuState="" />}
      </div>
    </nav>
  );
}

export default Navigation;
