import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import Home from './js/pages/Home';
import Navigation from './js/components/Navigation';
import Footer from './js/components/Footer';

class App extends Component {
  render() {
    return (
      <Router>
        <Navigation />
        <div className="container-page-structure">
          <Routes>
            <Route exact path="/" element={<Home />}/>
            {/* <Route exact path="/about" element={<Home />}/> */}
          </Routes>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
